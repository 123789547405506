<template>
  <b-modal
    :id="modalAccess"
    hide-header
    hide-footer
    hide-header-close
    size="xl"
    body-bg-variant="gray-lighten"
  >
    <b-row align="center">
      <b-col class="access-title-label">
        {{ $t('views.client.details.body.left.enzocard-access.history-access') }}
      </b-col>
    </b-row>
    <div class="border-bottom-grey-dark mt-3 mb-3">
    </div>
    <b-card
      class="history-card"
    >
      <d-table
        :key="componentKey + 'table'"
        :tablefields="tableFields"
        :per-page="itemsPerPage"
        :totalRows="totalRows"
        :items="items"
        :is-busy="isBusy"
        :show-pagination="true"
        @current-page:update="onCurrentPageUpdate"
      >
      </d-table>
    </b-card>
    <b-row>
      <b-col align="right">
        <csv-downloader
          :data="csvData"
          :name="csvName"
        >
          <label id="linker" hidden>
            {{ $t('components.custom.clients.history.payment-token-list-modal.title') }}
          </label>
          <d-button
            text="general.actions.export"
            class="d-btn btn d-btn-default font-text-title"
            icon="fa fa-external-link mr-1"
            @on:button-click="onExport"
          />
        </csv-downloader>
      </b-col>
      <b-col align="left">
        <d-button
          text="general.actions.dismiss"
          class="d-btn btn d-btn-danger font-text-title"
          @on:button-click="$bvModal.hide(modalAccess)"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>

import {getClubAccessHistory} from "@api/doinsport/services/enzocard/access-history.api";
import EnzocardAccessHistory from "@/classes/doinsport/EnzocardAccessHistory";

export default {
  data: () => ({
    componentKey: 1,
    itemsPerPage: 10,
    currentPage: 1,
    totalRows: 1,
    isBusy: true,
    items: [],
    csvData: [],
  }),
  components: {
    CsvDownloader: () => import ('vue-json-csv'),
  },
  props: {
    modalAccess: {
      type: String,
      default: 'modal-history-access'
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    display: function () {
      this.loadAccessHistory(this.currentPage);
      this.$bvModal.show(this.modalAccess);
    }
  },
  computed: {
    csvName() {
      return this.$t('components.custom.clients.history.payment-token-list-modal.title') + ' ' + this.$moment.utc().format('DD/MM/YYYY') + '.csv';
    },
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    tableFields() {
      return [
        {
          key: "occuratedAt",
          label: this.$t('views.client.details.body.left.enzocard-access.date'),
          sortable: true
        },
        {
          key: "incidence",
          label: this.$t('views.client.details.body.left.enzocard-access.incidence'),
          sortable: true
        },
        {
          key: "historyAccessType",
          class: this.alignCellsToLeft,
          label: this.$t('views.client.details.body.left.enzocard-access.access-type'),
          sortable: true
        },
        {
          key: "operationType",
          label: this.$t('views.client.details.body.left.enzocard-access.operation')
        },
      ];
    },
  },
  methods: {
    onExport() {
      document.getElementById('linker').click();
    },
    accessType(access) {
      return access.clubClientPaymentTokenOperations.length > 0 ?
        this.$t('views.client.details.body.left.enzocard-access.credit') :
        this.$t('views.client.details.body.left.enzocard-access.access-rules');
    },
    onCurrentPageUpdate(page) {
      this.currentPage = page;
      this.loadAccessHistory(page);
    },
    loadAccessHistory(currentPage) {
      this.items = [];
      this.csvData = [];
      this.isBusy = true;

      getClubAccessHistory(this.$route.params.id, currentPage, this.itemsPerPage).then((response) => {
        this.totalRows = response.data['hydra:totalItems'];

        for (const item of response.data['hydra:member']) {
          const access = new EnzocardAccessHistory(item, {serialize: true});

          access.occuratedAt = this.$toTimezone(item.occurredAt).format('DD/MM/YYYY HH:mm');
          access.historyAccessType = this.accessType(item)
          access.incidence = this.$t('views.client.details.body.left.enzocard-access.list-of-incidence.' + item.incidence);

          let csvItem = {};
          csvItem[this.$t('views.client.details.body.left.enzocard-access.date')] = access.occuratedAt;
          csvItem[this.$t('views.client.details.body.left.enzocard-access.incidence')] = access.incidence;
          csvItem[this.$t('views.client.details.body.left.enzocard-access.access-type')] = access.historyAccessType;
          csvItem[this.$t('views.client.details.body.left.enzocard-access.operation')] = access.operationType;

          this.items.push(access);
          this.csvData.push(csvItem);
        }
      })
        .finally(() => {
          this.isBusy = false;
        })
    }
  },
  created() {
    this.loadAccessHistory(this.currentPage);
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";
@import "@lazy/client/details/_enzocard-access-details.scss";
</style>
